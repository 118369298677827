<template>
  <div class="container">
    <el-form :model="biddingData"
             size="small"
             :rules="rules"
             ref="ruleForm"
             label-width="100px"
             label-suffix=":">
      <el-form-item label="项目名称" prop="name">
        {{project.name}}
      </el-form-item>
      <el-form-item label="招标编号" prop="code">
        {{project.code}}
      </el-form-item>
      <template v-if="projectEnd">
        <el-form-item label="是否分包" prop="isChunk">
          {{project.isChunk}}
        </el-form-item>
        <el-form-item label="选择分包" prop="chunkNames" v-if="project.isChunk === '分包'">
          <el-select v-model="biddingData.chunkNames" multiple placeholder="请选择" :disabled="isDisabled">
            <el-option
              v-for="item in project.chunkNames"
              :key="item.value"
              :label="item.value"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位名称" prop="unitName">
          <el-input v-model="biddingData.unitName" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="biddingData.linkMan" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="businessLicense">
          <upload v-if="!isDisabled" accept="image" ref="uploadImage"
                  :fileList="businessLicenseFieldList"
                  @uploadSuccess="uploadBusinessLicenseSuccess"/>
          <template v-else>
            <img
              style="width: 100px; height: 100px"
              :src="biddingData.businessBusinessLicenseContent"/>
          </template>
        </el-form-item>
        <el-form-item label="授权委托书" prop="letterOfAttorney">
          <template v-if="isDisabled">{{ biddingData.letterOfAttorneyName }}</template>
          <upload v-else accept="sqwds" ref="uploadLetterOfAttorney" responseType="name"
                  :fileList="letterOfAttorneyFieldList"
                  @uploadSuccess="uploadLetterOfAttorney"/>
        </el-form-item>
        <el-form-item label="报名表" prop="applicationForm">
          <template v-if="isDisabled">{{ biddingData.applicationFormName }}</template>
          <upload v-else accept="bmb" ref="uploadApplicationForm" responseType="name"
                  :fileList="applicationFormFieldList"
                  @uploadSuccess="uploadApplicationForm"/>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="biddingData.phone" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="座机号" prop="area" class="area">
          <el-input v-model="biddingData.area" placeholder="区号" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="" prop="telephone" class="telephone">
          <el-input v-model="biddingData.telephone" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="biddingData.email" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="投标方式" prop="pushType" @change="onChange">
          <el-radio-group v-model="biddingData.pushType" :disabled="isDisabled">
            <el-radio label="独立投标" checked>独立投标</el-radio>
            <el-radio label="联合投标">联合投标</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="联合单位1" prop="unionUnit1" v-show="biddingData.pushType === '联合投标'">
          <el-input v-model="biddingData.unionUnit1" :disabled="isDisabled"></el-input>
        </el-form-item>
        <el-form-item label="联合单位2" prop="unionUnit2" v-show="biddingData.pushType === '联合投标'">
          <el-input v-model="biddingData.unionUnit2" :disabled="isDisabled"></el-input>
        </el-form-item>
      </template>
    </el-form>
    <div class="demo-drawer__footer">
      <template v-if="projectEnd">
        <template v-if="biddingData.status === 1">
          <el-button disabled type="primary" class="big">
            正在审核中
          </el-button>
        </template>
        <template v-else-if="biddingData.status === 2">
          <el-tooltip v-if="biddingData.downOrderId == 0" :content="'费用 ¥ ' + project.downMoney" placement="top">
            <el-button icon="el-icon-download" type="primary" class="big" :loading="payLoading" @click="downFile">缴费并下载招标文件</el-button>
          </el-tooltip>
          <el-button v-else icon="el-icon-download" type="primary" class="big" :loading="payLoading" @click="downFile">下载招标文件</el-button>

          <el-tooltip v-if="biddingData.downOrderId == 0" :content="'费用 ¥ ' + project.upMoney" placement="top">
            <el-button icon="el-icon-upload2" type="primary" class="big" :loading="payLoading" @click="upFile">缴费并上传投标文件</el-button>
          </el-tooltip>
          <el-button v-else icon="el-icon-upload2" type="primary" class="big" :loading="payLoading" @click="upFile">上传投标文件</el-button>
        </template>
        <el-button v-else type="primary" class="big" @click="saveBidding" :loading="saveLoading">
          {{biddingData.status === 3 ? '重新上传' : '我要报名' }}
        </el-button>
      </template>
      <template v-else>
        <el-button disabled class="big">
          报名已经结束
        </el-button>
      </template>
    </div>


    <!--上传投标文件zip-->
    <el-dialog :visible="dialogUpVisible" append-to-body>
      <div>
        <upload accept="tender" @uploadSuccess="uploadBiddingFilePathSuccess"></upload>
        <br/>
        <span style="color: red; font-size: 20px;">上传的文件请自行加密！！！</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUpVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveBiddingFilePath">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 缴费窗口 -->
    <el-dialog
      :visible.sync="dialogPay.visible"
      width="300px"
      :title="dialogPay.title"
      :close-on-click-modal="false"
      :before-close="handleClose"
      append-to-body
    >
      <div style="width: 100%; text-align: center">
        <img width="200px" height="200px" :src="dialogPay.url" />
        <p>请使用微信扫码支付</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import QRCode from 'qrcode'
  import upload from '../components/Upload'

  export default {
    name: 'bidding',
    components: { upload },
    props: ['project', 'visible'],
    data () {
      return {
        biddingData: {},
        biddingDataField: { // 表单数据
          projectId: '',
          id: '',
          unitName: '',
          linkMan: '',
          phone: '',
          area: '',
          telephone: '',
          email: '',
          chunkNames: [],
          pushType: '独立投标',
          unionUnit1: '',
          unionUnit2: '',
          biddingFilePath: '',
          businessLicense: '',
          downOrderId: '',
          upOrderId: '',
          project: {},
          letterOfAttorney: '',
          applicationForm: ''
        },
        rules: {
          chunkNames: [
            { required: true, message: '请选择分包', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (this.project.isChunk === '分包' && value.length === 0) {
                  callback(new Error('请选择分包'))
                } else {
                  callback()
                }
              },
              trigger: 'blur'
            }
          ],
          unitName: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
          businessLicense: [{ required: true, message: '请上传营业执照', trigger: 'blur' }],
          letterOfAttorney: [{ required: true, message: '请上传授权委托书', trigger: 'blur' }],
          applicationForm: [{ required: true, message: '请上报名表', trigger: 'blur' }],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (!/^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(value)) {
                  callback(new Error('手机号码错误'))
                } else {
                  callback()
                }
              },
              trigger: 'blur'
            }
          ],
          area: [
            { required: true, message: '请输入区号', trigger: 'blur' },
            { min: 3, max: 4, message: '请输入正确的区号', trigger: 'blur' }
          ],
          telephone: [
            { required: true, message: '请输入座机号', trigger: 'blur' },
            { min: 7, max: 9, message: '请输入正确的座机号', trigger: 'blur' }
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            {
              validator: (rule, value, callback) => {
                if (!/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value)) {
                  callback(new Error('邮箱错误'))
                } else {
                  callback()
                }
              },
              trigger: 'blur'
            }
          ],
          linkMan: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
          unionUnit1: [
            {
              validator: (rule, value, callback) => {
                if (!value && this.biddingData.pushType === '联合投标') {
                  callback(new Error('请至少填写一个联合单位'))
                } else {
                  callback()
                }
              },
              trigger: 'blur'
            }
          ]
        },
        saveLoading: false,
        dialogUpVisible: false,
        biddingFilePath: '', // 上传标书路径

        timer: null,
        payLoading: false,
        dialogPay: {
          url: '',
          title: '',
          visible: false
        },
        businessLicenseFieldList: [],
        letterOfAttorneyFieldList: [],
        applicationFormFieldList: []
      }
    },
    computed: {
      projectEnd () {
        return new Date(this.project.downLoadEndTime) > new Date()
      },
      isDisabled () {
        return this.biddingData.status === 1 || this.biddingData.status === 2
      },
      biddingStatus () {
        return this.biddingData.status
      }
    },
    watch: {
      visible: {
        handler (val) {
          this.$nextTick(function () {
            console.log('this.$refs.ruleForm ', this.$refs.ruleForm)
            this.$refs.ruleForm.clearValidate()
          })
          val && this.getBidding()
        },
        immediate: true
      }
    },
    methods: {
      // 获取投标数据
      getBidding () {
        return this.$http.get('/index/bidding/read/' + this.project.id)
          .then(res => {
            const bidding = res.data || null
            if (bidding && bidding.length !== 0) {
              bidding.chunkNames = bidding.chunkNames ? bidding.chunkNames.split('，') : []

              this.businessLicenseFieldList = bidding.businessLicense ? [bidding.businessLicense] : []
              this.letterOfAttorneyFieldList = bidding.letterOfAttorney ? [{ url: bidding.letterOfAttorney, name: bidding.letterOfAttorneyName }] : []
              this.applicationFormFieldList = bidding.applicationForm ? [{ url: bidding.applicationForm, name: bidding.applicationFormName }] : []

              this.biddingData = bidding
            } else {
              this.biddingData = JSON.parse(JSON.stringify(this.biddingDataField))
            }
          })
      },
      // 上传标书
      uploadSuccess (fileList) {
        const file = fileList[0]
        if (file) {
          this.biddingData.biddingFilePath = file
        }
      },
      // 上传营业执照
      uploadBusinessLicenseSuccess (fileList) {
        const file = fileList[0]
        if (file) {
          this.biddingData.businessLicense = file
        }
      },
      // 上传授权委托书
      uploadLetterOfAttorney (fileList) {
        const file = fileList[0]
        if (file) {
          this.biddingData.letterOfAttorney = file.url
          this.biddingData.letterOfAttorneyName = file.name
        }
      },
      // 上传报名表
      uploadApplicationForm (fileList) {
        const file = fileList[0]
        if (file) {
          this.biddingData.applicationForm = file.url
          this.biddingData.applicationFormName = file.name
        }
      },
      // 上传标书路径
      uploadBiddingFilePathSuccess (fileList) {
        const file = fileList[0]
        if (file) {
          this.upBiddingFilePath = file
        }
      },
      // 保存上传标书路径
      saveBiddingFilePath () {
        this.$confirm('确保您的资料安全，请自行加密！', '提示', {
          confirmButtonText: '已加密',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$confirm('确保您的资料安全，请自行加密！', '提示', {
            confirmButtonText: '已加密',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$confirm('确保您的资料安全，请自行加密！', '提示', {
              confirmButtonText: '已加密',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$http.put('/index/bidding/saveBiddingFilePath', { id: this.biddingData.id, path: this.upBiddingFilePath })
                .then(res => {
                  if (res) {
                    this.dialogUpVisible = false
                    this.biddingFilePath = ''
                    this.getBidding()
                  }
                })
            })
          })
        })
      },
      onChange (val) {
        if (val === '独立投标') {
          this.biddingData.unionUnit1 = ''
          this.biddingData.unionUnit2 = ''
        }
      },
      cancelForm () {
        this.saveLoading = false
        this.$refs.uploadImage && this.$refs.uploadImage.removeFileList()
      },
      // 保存投标数据
      saveBidding () {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            const params = JSON.parse(JSON.stringify(this.biddingData))
            params.projectId = this.project.id
            params.chunkNames = params.chunkNames.join('，')

            if (params.id) {
              this.$http.put('/index/bidding/edit', params)
                .then(() => {
                  this.$alert('提交成功, 请等待审核！')
                    .then(() => {
                      this.getBidding()
                    })
                })
                .catch(err => {
                  const msg = err.message || '提交失败，请联系管理员'
                  this.$message.error(msg)
                })
            } else {
              this.$http.post('/index/bidding/add', params)
                .then(() => {
                  this.$alert('提交成功, 请等待审核！')
                    .then(() => {
                      this.getBidding()
                    })
                })
                .catch(err => {
                  const msg = err.message || '提交失败，请联系管理员'
                  this.$message.error(msg)
                })
            }
          } else {
            return false
          }
        })
      },
      // 下载投标文件
      downFile () {
        if (this.biddingData.downOrderId > 0) {
          this.$http.get('/index/bidding/getProjectKey', { params: { id: this.project.id } })
            .then(res => {
              const ele = document.createElement('a')
              ele.setAttribute('href', '/index/download/' + res.data)
              ele.setAttribute('target', '_blank')
              ele.click()
              ele.remove()
            })
        } else {
          this.payDown()
        }
      },
      // 下载文件缴费
      async payDown () {
        if (!this.biddingData.id) return

        try {
          this.payLoading = true

          const res = await this.$http.put('/index/bidding/payDown', { id: this.biddingData.id })

          const { id, needPay, wechat } = res.data

          if (needPay) {
            const url = await QRCode.toDataURL(wechat)

            this.dialogPay.url = url
            this.dialogPay.visible = true
            this.dialogPay.title = '招标文件缴费'

            this.checkOrder(id, 'down')
          } else {
            this.biddingData.downOrderId = id
            this.$message.success('缴费成功！')
          }

          this.payLoading = false
        } catch(err) {
          this.payLoading = false
        }
      },
      // 上传文件
      upFile () {
        if (this.biddingData.upOrderId > 0) {
          this.dialogUpVisible = true
        } else {
          this.payUp()
        }
      },
      // 上传文件缴费
      async payUp () {
        if (!this.biddingData.id) return

        try {
          this.payLoading = true

          const res = await this.$http.put('/index/bidding/payUp', { id: this.biddingData.id })

          const { id, needPay, wechat } = res.data

          if (needPay) {
            const url = await QRCode.toDataURL(wechat)

            this.dialogPay.url = url
            this.dialogPay.visible = true
            this.dialogPay.title = '上传标书缴费'

            this.checkOrder(id, 'up')
          } else {
            this.dialogUpVisible = true
            this.biddingData.upOrderId = id
            this.$message.success('缴费成功！')
          }

          this.payLoading = false
        } catch(err) {
          this.payLoading = false
        }
      },
      handleClose(done) {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        done()
      },
      checkOrder(id, type) {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }

        this.$http.get('/index/bidding/order?id=' + id)
          .then(res => {
            const { status } = res.data

            if (status) {
              this.dialogPay.visible = false
              this.$message.success('缴费成功！')

              if (type == 'up') {
                this.dialogUpVisible = true
                this.biddingData.upOrderId = id
              } else {
                this.biddingData.downOrderId = id
              }
            } else {
              this.timer = setTimeout(() => {
                this.checkOrder(id, type)
              }, 1000)
            }
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
  }

  ::v-deep .el-form {
    flex: 1;
    padding: 10px;
    overflow: auto;

    .el-select {
      width: 100%;
    }

    .area {
      float: left;

      .el-form-item__content {
        width: 100px;
      }
    }

    .telephone {
      float: left;

      .el-form-item__content {
        position: relative;
        width: 200px;
        margin-left: 20px !important;

        &:before {
          content: "-";
          position: absolute;
          left: -13px;
          top: 0;
        }
      }

      & + .el-form-item {
        clear: both;
      }
    }
  }

  .demo-drawer__footer {
    padding: 10px;
    box-shadow: 5px 2px 10px #e6e6e6;
    text-align: center;
    display: flex;
    justify-content: space-around;

    .el-button {
      flex: 1;
    }
  }

  .el-button.big {
    width: 80%;
    border-radius: 20px;
  }
</style>
