<template>
  <div class="container">
    <header>
      <div class="logo">
        <h1>诚亿达电子招标服务平台</h1>
      </div>
      <div class="user">
        <el-dropdown @command="handleCommand">
          <!--<div class="logout" @click="onLogout"><i class="el-icon-switch-button"></i></div>-->
          <div class="info el-dropdown-link">
            <span>欢迎您，{{ userInfo.name || '' }}</span>
            <i class="el-icon-s-custom"></i>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="password" icon="el-icon-lock">密码修改</el-dropdown-item>
            <el-dropdown-item command="logout" icon="el-icon-switch-button " divided>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </header>
    <section class="main">
      <div class="search">
        <el-form :inline="true" :model="searchForm" ref="searchForm" label-suffix=":">
          <el-form-item prop="user" label="项目所属">
            <el-select
              v-model="searchForm.user"
              placeholder="请输入"
              autocomplete="off"
              clearable
            >
              <el-option :value="0" label="全部"></el-option>
              <el-option :value="1" label="我的"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="projectName" label="招标项目">
            <el-input
              v-model="searchForm.projectName"
              placeholder="请输入"
              autocomplete="off"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item prop="projectName" label="开标时间">
            <el-date-picker
              v-model="searchForm.startTime"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="getData()">
              搜索
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="table"
        :data="tableData"
        stripe
        highlight-current-row
        height="100%"
      >
        <el-table-column type="index" align="center" label="序号" width="90">
        </el-table-column>
        <el-table-column prop="name" label="招标项目" min-width="100" >
        </el-table-column>
        <el-table-column prop="downLoadStartTime" label="文件获取开始时间" width="150" >
        </el-table-column>
        <el-table-column prop="downLoadEndTime" label="文件获取结束时间" width="180" >
        </el-table-column>
        <el-table-column prop="publicTime" label="公告时间" width="120" >
        </el-table-column>
        <el-table-column prop="startTime" label="开标时间" width="150">
        </el-table-column>
        <el-table-column prop="biddingStatus" label="投标状态" width="150" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.biddingStatus" :style="{color: scope.row.biddingStatus.color }">
              {{scope.row.biddingStatusShow.text}}
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="" width="150" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.projectEnd" disabled type="text">报名已经结束</el-button>
            <el-button v-else @click="signUp(scope.row)" type="text">{{scope.row.btnText}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <footer>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageInfo.page"
        :page-size="pageInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
      >
      </el-pagination>
    </footer>

    <el-drawer
      ref="drawer"
      title="信息登记"
      :visible.sync="biddingVisible"
      size="40%"
      direction="rtl"
    >
      <bidding :project="detailData" :visible="biddingVisible"></bidding>
    </el-drawer>


  </div>
</template>

<script>
  import bidding from './bidding'
  import dayjs from 'dayjs'
  export default {
    name: 'home',
    components: { bidding },
    data () {
      return {
        pageInfo: {
          page: 1,
          size: 10,
          total: 0
        },
        tableData: [],
        biddingVisible:false,
        userInfo: {},
        detailData: {}, // 项目详情
        statusConfig: {
          1: {text: '审核中', 'color': '#333' },
          2: {text: '审核通过', 'color': 'green' },
          3: {text: '被驳回', 'color': 'red' },
        },
        searchForm: {
          user: 0,
          projectName: '',
          startTime: []
        },
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
      }
    },

    created () {
      this.getMe()
      this.getData()
    },
    watch: {
      biddingVisible (val) {
        !val && this.getData()
      }
    },
    methods: {
      getMe() {
        this.$http.get('/index/me').then(res => {
          this.userInfo = res.data
        })
      },
      // 获取项目
      getData () {
        const params = {
          size: this.pageInfo.size,
          page: this.pageInfo.page,
          projectName: this.searchForm.projectName,
          user: this.searchForm.user,
          startTime1: this.searchForm.startTime ? this.searchForm.startTime[0] : '',
          startTime2: this.searchForm.startTime ? this.searchForm.startTime[1] : '',
        }
        this.$http.get('/index/project', { params: params})
          .then(res => {
            const data = res.data.data || []
            this.tableData = data.map(val => {
              console.log('JSON.parse(val.chunkNames) ', JSON.parse(val.chunkNames))
              val.chunkNames = val.chunkNames ? JSON.parse(val.chunkNames) : []
              val.downLoadStartTime = dayjs(val.downLoadStartTime).format('YYYY-MM-DD')
              val.downLoadEndTime = dayjs(val.downLoadEndTime).format('YYYY-MM-DD HH:mm')
              val.publicTime = dayjs(val.publicTime).format('YYYY-MM-DD')
              val.projectEnd = new Date(val.downLoadEndTime) < new Date()
              val.startTime = dayjs(val.startTime).format('YYYY-MM-DD HH:mm')
              val.biddingStatusShow = this.statusConfig[val.biddingStatus] || ''

              if (val.biddingStatus === 1) {
                val.btnText = '已报名审核中'
              } else if (val.biddingStatus === 2) {
                val.btnText = '报名成功'
              } else if (val.biddingStatus === 3) {
                val.btnText = '报名已被驳回'
              } else {
                val.btnText = '报名'
              }

              return val
            })
            this.pageInfo.total = res.data.total || 0
          })
      },
      // 显示项目详情，投标
      signUp (row) {
        this.$http.get('/index/project/' + row.id).then(res => {
          const data = res.data || {}
          data.chunkNames = data.chunkNames ? JSON.parse(data.chunkNames) : []
          this.detailData = data
          this.biddingVisible = true
        })
      },
      // 显示驳回信息
      showReject (row) {
        this.$confirm(row.rejectMessage, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.signUp(row)
        }).catch(() => {
          this.$message.info('已取消');
        });
      },
      handleSizeChange (val) {
        this.pageInfo.size = val
        this.getData()
      },
      handleCurrentChange (val) {
        this.pageInfo.page = val
        this.getData()
      },
      handleCommand (command) {
        if (command === 'password') {
          // 1
        }

        if (command === 'logout') {
          this.$confirm(`确认退出登录吗？`, '提示', {
            type: 'warning',
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(() => {
            this.$http.get('/index/logout')
              .then(() => {
                this.$message.success('退出登录！')
                this.$router.replace('/login')
                localStorage.removeItem('token')
              })
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  header {
    color: #ffffff;
    height: 100px;
    background-image: url("../assets/images/header.jpg");
    position: relative;

    h1 {
      padding: 10px 20px;
    }
  }

  .search {
    padding: 10px;
    border-bottom: 1px solid #C0C4CC;
  }

  .user {
    position: absolute;
    right: 20px;
    top: 18px;

    i {
      color: #ffffff;
      font-size: 24px;
    }

    .info {
      float: right;
      margin-right: 15px;

      span {
        color: #fff;
        padding-right: 10px;
      }
    }

    .logout {
      float: right;
    }
  }

  .main {
    overflow: auto;
    flex: 1;
    height: 100%;

  }

  a:hover {
    text-decoration: underline;
    color: #0085f0;
    cursor: pointer;
  }

  footer {
    text-align: center;
    padding: 10px;
  }

  ::v-deep .el-drawer__header {
    margin: 0;
    box-shadow: 5px 2px 10px #e6e6e6;
    padding: 20px 20px 20px;
  }


</style>
