<template>
  <el-upload
    class="upload-demo"
    :headers="selfHeaders"
    action="/index/upload"
    :data="data"
    :before-upload="beforeUpload"
    :on-remove="handleRemove"
    :on-success="handleSuccess"
    :on-exceed="handleExceed"
    :file-list="selfFileList"
    :multiple="multiple"
    :limit="limit"
    :accept="acceptArr[accept].join(',')"
    list-type="text">
    <el-button size="small" icon="el-icon-upload2" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">
      只能上传{{acceptArr[accept].join('/')}}文件，且不超过{{acceptSize[accept]}}M
    </div>
  </el-upload>
</template>

<script>
  export default {
    name: 'Upload',
    data () {
      return {
        selfFileList: [],
        selfHeaders: {
          Authorization: localStorage.getItem('token')
        },
        acceptArr: {
          image: ['.jpg', '.jpeg', '.png', '.gif'],
          video: ['.mp4'],
          tender: ['.zip', '.rar'],
          sqwds: ['.doc', '.docx'], // 授权委托书
          bmb: ['.doc', '.docx'] // 授权委托书
        },
        acceptSize: {
          image: '5',
          video: '50',
          tender: '20',
          sqwds: '5',
          bmb: '5'
        },
        fileName: {
          image: '营业执照',
          video: '音频文件',
          tender: '标书',
          sqwds: '授权委托书',
          bmb: '报名表'
        },
        message: null,
        data: {}
      }
    },
    props: {
      fileList: [Array, String],
      multiple: {
        type: Boolean,
        default: false
      },
      limit: {
        type: Number,
        default: 1
      },
      headers: {
        type: Object,
        default: function () {
          return {}
        }
      },
      accept: {
        type: String,
        default: 'image'
      },
      responseType: {
        type: String,
        default: 'default'
      }
    },
    watch: {
      fileList: {
        handler (val) {
          if (val instanceof Array) {
            this.selfFileList = []
            const fileList = val //JSON.parse(JSON.stringify(val))
            for (let i = 0; i < fileList.length; i++) {
              if (typeof fileList[i] === 'string') {
                this.selfFileList.push({
                  url: fileList[i] || '',
                  name: this.fileName[this.accept] || '营业执照'
                })
              } else {
                this.selfFileList.push({
                  url: fileList[i].url || '',
                  name:  fileList[i].name || ''
                })
              }
            }
          } else {
            this.selfFileList = val ? [{ url: val }] : []
          }
        },
        immediate: true
      },
    },
    created () {
      Object.assign(this.selfHeaders, this.headers)
      //
      this.data['accept'] = this.accept
    },
    methods: {
      beforeUpload (file) {
        // console.log('file ', file)
        if (this.accept === 'image' && !/^image\/(gif|png|jpg|jpeg)$/.test(file.type)) {
          this.$message.error('请上传文件' + this.acceptArr[this.accept].join('/') + '格式!')
          return false
        }
        if (this.accept === 'video' && !/^video\/(mp4)$/.test(file.type)) {
          this.$message.error('请上传文件' + this.acceptArr[this.accept].join('/') + '格式!')
          return false
        }
        if (this.accept === 'tender') {
          const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
          if (!this.acceptArr[this.accept].includes('.' + suffix)) {
            this.$message.error('请上传文件' + this.acceptArr[this.accept].join('/') + '格式!')
            return false
          }
        }
        if (this.accept === 'sqwds' || this.accept === 'bmb') {
          const suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
          if (!this.acceptArr[this.accept].includes('.' + suffix)) {
            this.$message.error('请上传文件' + this.acceptArr[this.accept].join('/') + '格式!')
            return false
          }
        }

        let size = ''
        let acceptSize = this.acceptSize[this.accept] || ''
        acceptSize = acceptSize * 1024
        if (acceptSize < 0.1) { // 如果小于0.1KB转化成B
          size = acceptSize + 'B'
        } else if (acceptSize < 0.1 * 1024) { // 如果小于0.1MB转化成KB
          size = acceptSize + 'KB'
        } else if (acceptSize < 0.1 * 1024 * 1024) { // 如果小于0.1GB转化成MB
          size = acceptSize / (1024) + 'MB'
        } else { // 其他转化成GB
          size = acceptSize / (1024 * 1024) + 'GB'
        }

        const isOk = file.size / 1024 < acceptSize
        if (!isOk) {
          if (!this.message) {
            this.message = this.$message.error('上传文件大小不能超过' + size)
          }
          return false
        }
      },
      handleRemove (file, fileList) {
        this.parse(fileList)
      },
      handleExceed () {
        this.$message.error('只能上传' + this.limit + '个文件!')
      },
      handleSuccess (response, file, fileList) {
        this.parse(fileList)
      },
      removeFileList () {
        this.selfFileList = []
      },
      parse (fileList) {
        let arr = []
        for (let i = 0; i < fileList.length; i++) {
          let file = fileList[i]
          if (file.response) {
            if (this.responseType == 'name') {
              arr.push({
                name: file.name,
                url: file.response.data,
              })
            } else {
              arr.push(file.response.data)
            }
          } else {
            if (this.responseType == 'name') {
              arr.push({
                name: file.name,
                url: file.url,
              })
            } else {
              arr.push(file.url)
            }
          }
        }
        this.$emit('uploadSuccess', arr)
      }
    }
  }
</script>
