<template>
  <el-container style="height: 100%">
    <el-aside width="auto">
      <el-menu
        :default-active="menus[0].path"
        class="el-menu-vertical-demo"
        router
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        :collapse="isCollapse">
        <template v-for="menu in menus">
          <el-menu-item :key="menu.id" :index="menu.path">
            <i :class="menu.icon"></i>
            <span slot="title">{{menu.name}}</span>
          </el-menu-item>
        </template>
      </el-menu>
      <!-- 收起 -->
      <div class="collapse" @click="isCollapse = !isCollapse">
        <i :class="isCollapse ? 'el-icon-d-arrow-right' : 'el-icon-d-arrow-left'"></i>
      </div>
    </el-aside>

    <el-container style="overflow: auto">
      <el-header height="40px">
        <div class="logo"><img src="../assets/images/logo.png"></div>
        <div class="system-name"></div>
        <div class="logout" @click="onEditPassword"><i class="el-icon-lock"></i></div>
        <div class="logout" @click="onLogout"><i class="el-icon-switch-button"></i></div>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="500px"
      center>
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="form-main-center"
          label-suffix=":"
        >
          <el-form-item label="旧密码" prop="old_password">
            <el-input
              type="password"
              v-model="ruleForm.old_password"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password_repeat">
            <el-input
              type="password"
              v-model="ruleForm.password_repeat"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditPasswordDo">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
  export default {
    name: 'Home',
    data () {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (!/^[0-9A-Za-z]{7,24}$/.test(value)) {
          callback(new Error('密码应为7-16位的数字、字母组合'))
        } else {
          if (this.ruleForm.password_repeat !== '') {
            this.$refs.ruleForm.validateField('password_repeat')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入确认密码'))
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        isCollapse: false,
        menus: [],
        ruleForm: {
          old_password: '',
          password: '',
          password_repeat: ''
        },
        rules: {
          old_password: [{
            required: true,
            message: '请输入旧密码',
            trigger: 'blur'
          }],
          password: [{
            validator: validatePass,
            trigger: 'blur'
          }],
          password_repeat: [{
            validator: validatePass2,
            trigger: 'blur'
          }]
        },
        dialogVisible: false
      }
    },
    created () {
      this.menus = [{
        id: '3',
        name: '报名申请记录',
        path: '/bidding',
        icon: 'el-icon-s-check'
      }, {
        id: '1',
        name: '投标账号管理',
        path: '/bidder',
        icon: 'el-icon-s-custom'
      }, {
        id: '2',
        name: '招标项目管理',
        path: '/project',
        icon: 'el-icon-s-cooperation'
      }]
    },
    mounted () {
      this.$router.replace(this.menus[0].path)
    },
    methods: {
      onLogout () {
        this.$confirm(`确认退出登录吗？`, '提示', {
          type: 'warning',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          this.$http.get('/admin/logout')
            .then(() => {
              this.$message.success('退出登录！')
              this.$router.replace('/login')
              localStorage.removeItem('admin_token')
            })
        })
      },
      onEditPassword () {
        this.dialogVisible = true
      },
      onEditPasswordDo () {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.$http.put('/admin/password', this.ruleForm)
              .then(() => {
                this.$message.success('修改成功！')
              })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .el-header {
    border-bottom: 1px solid #ccc;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    color: #666;

    .logo {
      height: 100%;

      img {
        width: auto;
        height: 100%;
      }
    }

    .system-name {
      flex: 1;
      align-self: flex-start;
    }

    .logout {
      width: 50px;
      text-align: center;
      color: #409eff;
    }
  }

  .el-aside {
    border-right: solid 1px #e6e6e6;
    /*padding-top: 10px;*/
    display: flex;
    flex-direction: column;

    .collapse {
      align-self: center;
      position: relative;
      height: 44px;
      width: 100%;
      line-height: 44px;
      text-align: center;
      box-shadow: 5px 2px 10px #e6e6e6;
      background-color: rgb(84, 92, 100);

      i {
        color: #ffffff;
        font-size: 20px;
      }

      &:after {
        content: " ";
        border-radius: 50%;
        border: 1px solid #e6e6e6;
        position: absolute;
        left: 50%;
        top: 6px;
        transform: translateX(-50%);
        padding: 13px;
      }
    }
  }

  .el-menu {
    flex: 1;
    border: none;
    height: 100%;
    overflow: auto;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
  }

  .el-main {
    padding: 0;
  }
</style>
